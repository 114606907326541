<template>
    <div class="flex h-full w-full flex-col text-sm" v-loader="loading">
        <h4 v-if="showFormTitle" class="text-xl font-semibold text-science-blue-900">{{ formTitle }}</h4>
        <page-message v-if="isActive"></page-message>
        <crmPicker
            :prefix="type"
            :disabled="isLocked()"
            :class="`${type}-crm`"
            :autofocus="isActive && !isLocked()"
            :show-connections="showConnections"
            @use-connection="useConnection"
            @new-connection="newConnection"
            @crmChange="crmChangeEvent"
        />
        <div v-if="show" class="flex h-full flex-col">
            <div class="border-b pb-4"></div>
            <div v-if="type === 'target' && hasPreview && !isDone" class="mt-4 flex">
                <base-checkbox
                    v-model="previewCheckboxChecked"
                    :label="$t('crm preview label')"
                    :data-test="dataTest('preview-checkbox')"
                />
                <base-popover
                    placement="top"
                    :content="previewHint"
                >
                    <template #reference>
                        <base-icon
                            class="ml-1"
                            name="question-circle"
                            prefix="far"
                            type="info"
                            clickable
                        />
                    </template>
                </base-popover>
            </div>
            <authorizationTabs
                v-if="hasAuthorizations && !previewCheckboxChecked"
                :data-path="dataPath"
                :authorizations="authorization"
                :update="true"
                :selectedCRM="selectedCRM"
                :isSupported="isSupported"
                :prefix="type"
                :state="state"
                :showAuthorizationDescription="showAuthorizationDescription"
                :showFilesCredentials="showFilesCredentials"
                :product="product"
                :inline-submit="inlineSubmit"
                @changeAuthorization="changeActive"
                @onFieldFocus="onFieldFocus"
                @changeTab="changeTab"
                @submit="data => $emit('submit', data)"
            />
            <div
                v-else-if="previewCheckboxChecked"
                class="flex h-full flex-col justify-between"
            >
                <div></div>
                <div class="flex justify-between border-t pt-4">
                    <div v-if="!isDone">
                        <base-button
                            type="primary"
                            round
                            :disabled="disableProceed"
                            :data-test="dataTest('test-connection-button')"
                            @click="submitPreview"
                        >
                            {{ $t('form button connect') }}
                        </base-button>
                    </div>
                    <div v-else class="w-full text-center">
                        <div class="font-semibold font-avenir">{{ $t('form button test connection verified') }}</div>
                    </div>
                </div>
            </div>
            <div v-else class="flex h-full flex-col justify-between">
                <div></div>
                <div class="flex justify-between border-t pt-4">
                    <div v-if="isDone" class="w-full text-center">
                        <div class="font-semibold font-avenir">{{ $t('form button test connection verified') }}</div>
                    </div>
                    <div v-else>
                        <base-button
                            type="primary"
                            round
                            :disabled="disableProceed"
                            :data-test="dataTest('test-connection-button')"
                            @click="submitWithoutAuthorization"
                        >
                            {{ connectButtonTitle }}
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    each,
    isEmpty,
    has,
    get,
    uniqueId,
} from 'lodash';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import CrmPicker from '@/components/crm-picker';
import PageMessage from '@/components/page-message';
import reactive from '@/assets/util/reactive-object';
import { connectionGetConnectData } from '@/modules/connection/api';
import { CONNECTION as MODULE_CONNECTION } from '@/modules/list';
import { CONNECTION } from '@/store/modules/acl-access/constants';
import crmCookiesMixin from '@/assets/mixins/crm-cookie';
import authorizationTabs from '../authorization-tabs/authorization-tabs';
import isAvailableAuthorization from '../../assets/util/is-available-authorization';
import getData from '../get-data/get-data';

export default {
    name:       'CrmAuthorizationForm',
    extends:    getData,
    components: {
        authorizationTabs,
        CrmPicker,
        PageMessage,
    },
    mixins:     [crmCookiesMixin],
    props:      {
        type:                         {
            type:     String,
            required: true,
            validator(type) {
                return ['source', 'target'].indexOf(type) !== -1;
            },
        },
        title:                        {
            type:     String,
            required: false,
        },
        locked:                       {
            type:    Boolean,
            default: false,
        },
        showAuthorizationDescription: {
            type:    Boolean,
            default: true,
        },
        showFormTitle:                {
            type:    Boolean,
            default: true,
        },
        showFilesCredentials:         {
            type:    Boolean,
            default: true,
        },
        state:                        {
            type:    String,
            default: 'active',
            validator(type) {
                return ['active', 'done', 'disabled'].indexOf(type) !== -1;
            },
        },
        product:                      {
            type: String,
        },
        showConnections:              {
            type: Boolean,
        },
        preloadConnections:           {
            type: Boolean,
        },
        useCookiePlatform:            {
            type: Boolean,
        },
        inlineSubmit:                 {
            type: Boolean,
        },
    },
    data() {
        return {
            authorization:          undefined,
            selectedCRM:            undefined,
            crmData:                undefined,
            hasPreview:             false,
            previewCheckboxChecked: false,
            active:                 undefined,

            show:    true,
            loading: false,
        };
    },
    computed: {
        ...mapGetters('aclAccess', ['isGranted']),
        ...mapGetters('platform', ['getPlatform']),
        ...mapGetters('connection', ['connectionsList']),
        ...mapState('connection', {
            connectionsListLoading: state => state.connections.loading,
            connectionsMetaData:    state => state.connections.metaData,
        }),
        disableProceed() {
            return !this.isActive || isEmpty(this.selectedCRM);
        },
        formTitle() {
            if (this.title) {
                return this.title;
            }

            return this.type === 'source' ? this.$t('tmp Migrate your CRM data from') : this.$t('tmp Migrate your CRM data to');
        },
        previewHint() {
            const link = this.$filters.serviceLink('crm-preview');
            return this.$t('crm preview description', {
                linkOpen:  `<a href='${link}' target='_blank'>`,
                linkClose: '</a>',
            });
        },
        isSupported() {
            return this.crmData && get(this.crmData, 'isSupported');
        },
        hasAuthorizations() {
            let hasAuthorization = false;
            each(this.authorization, (authorization) => {
                if (isAvailableAuthorization(authorization.credential, this.type)) {
                    hasAuthorization = true;
                }
            });

            return hasAuthorization;
        },
        connectButtonTitle() {
            return has(this.authorization, 'file') ? this.$t('form button proceed') : this.$t('form button connect');
        },
        id() {
            return uniqueId('crmAuthorizationForm');
        },
        isDone() {
            return this.state === 'done';
        },
        isActive() {
            return this.state === 'active';
        },
        connectionsAvailable() {
            return this.$appModules.has(MODULE_CONNECTION) && this.isGranted(CONNECTION.LIST) && this.showConnections;
        },
    },
    async mounted() {
        const data = this.getData();

        if (this.connectionsAvailable && this.preloadConnections && !this.connectionsListLoading && this.isActive && this.connectionsMetaData?.count === undefined) {
            await this.loadConnections();
        }

        this.setPlatformData(data);
        this.checkPreview(data);

        if (data.message) {
            window.eventBus.$emit(
                this.$store.state.eventConstants.showPageMessage,
                {
                    type:    'danger',
                    content: data.message,
                },
            );
        }

        if (this.isActive) {
            this.subscribe();
        }
    },
    beforeDestroy() {
        this.unloadConnections();
        this.unSubscribe();
        this.reset();
    },
    watch:   {
        connectionsListLoading() {
            if (this.isActive) {
                this.loading = this.connectionsListLoading;
            }
        },
    },
    methods: {
        ...mapActions('connection', {
            loadConnections:   'LOAD_CONNECTIONS',
            unloadConnections: 'UNLOAD_CONNECTIONS',
        }),
        ...mapMutations({
            addEvent:      'events/addEvent',
            dropEvent:     'events/dropEvent',
            setPlatform:   'platform/SET_PLATFORM_DATA',
            clearPlatform: 'platform/CLEAR',
        }),
        subscribe() {
            this.addEvent(
                {
                    id:       this.id,
                    key:      'keypress',
                    callback: this.keyPressForm,
                },
            );
        },
        unSubscribe() {
            this.dropEvent(
                {
                    id:       this.id,
                    key:      'keypress',
                    callback: this.keyPressForm,
                },
            );
        },
        keyPressForm(event) {
            const key = event.keyCode;
            if (key === 13 && this.active && this.isActive) {
                if (this.hasAuthorizations === false) {
                    this.submitWithoutAuthorization();
                } else if (this.disabledSubmit === false) {
                    this.submitPreview();
                } else if (this.previewCheckboxChecked) {
                    this.submitPreview();
                }
            }
        },
        setPlatformData(applyingData) {
            let sourceData = {};

            if (has(applyingData, 'source.platforms')) {
                sourceData.list = applyingData.source.platforms;
            } else if (has(applyingData, 'platforms')) {
                sourceData.list = applyingData.platforms;
            } else if (has(applyingData, 'source') && !isEmpty(applyingData.source)) {
                sourceData.list = applyingData.source;
            }

            let targetData = {};

            if (has(applyingData, 'target.platforms')) {
                targetData.list = applyingData.target.platforms;
            } else if (has(applyingData, 'platforms')) {
                targetData.list = applyingData.platforms;
            } else if (has(applyingData, 'target') && !isEmpty(applyingData.target)) {
                targetData.list = applyingData.target;
            }

            if (has(applyingData, 'source.platform.type')) {
                sourceData.name = applyingData.source.platform.name;
                sourceData.type = applyingData.source.platform.type;
            }

            if (has(applyingData, 'target.platform.type')) {
                targetData.name = applyingData.target.platform.name;
                targetData.type = applyingData.target.platform.type;
            }

            if (!sourceData.type && this.useCookiePlatform && this.type === 'source') {
                sourceData = {
                    ...sourceData,
                    ...this.getCookiePlatformData('source', sourceData?.list ?? {}),
                };
            }

            if (!targetData.type && this.useCookiePlatform && this.type === 'target') {
                targetData = {
                    ...targetData,
                    ...this.getCookiePlatformData('target', targetData?.list ?? {}),
                };
            }

            this.setPlatform({
                prefix: 'source',
                data:   sourceData,
            });
            this.setPlatform({
                prefix: 'target',
                data:   targetData,
            });
            window.eventBus.$emit(this.$store.state.eventConstants.platformDataSet);
        },
        getCookiePlatformData(direction, platformList = {}) {
            const cookieData = this.getCrmCookie(direction);

            if (!cookieData || !has(platformList, cookieData.type)) return {};

            if (this.preloadConnections && this.connectionsList.filter(connection => connection.platform.type === cookieData.type).length > 0) {
                return {};
            }

            return {
                type: cookieData.type,
                name: cookieData.name,
            };
        },
        changeActive(active) {
            this.active = active;
        },
        checkPreview(data) {
            this.previewCheckboxChecked = Object.is(get(data, 'target.isPreview'), true);
        },
        reset() {
            this.clearPlatform(this.type);
            this.selectedCRM = null;
            this.crmData = null;
            this.authorization = null;
        },
        submitPreview() {
            const sendData = {
                isPreview: 'on',
                platform:  this.selectedCRM,
                csrf:      this.getData('csrf'),
            };
            window.eventBus.$emit(this.$store.state.eventConstants.showStepLoading);
            window.eventBus.$emit(
                this.$store.state.eventConstants.submitStep,
                sendData,
                (data) => {
                    if (data.redirect) {
                        window.location = data.redirect;
                    }
                    window.eventBus.$emit(this.$store.state.eventConstants.hideStepLoading);
                    window.eventBus.$emit(this.$store.state.eventConstants.applyData, data);
                },
                (data) => {
                    window.eventBus.$emit(this.$store.state.eventConstants.applyData, data);
                    window.eventBus.$emit(this.$store.state.eventConstants.hideStepLoading);
                },
            );
        },
        submitWithoutAuthorization() {
            const sendData = {
                authorization: this.active,
                platform:      this.selectedCRM,
                csrf:          this.getData('csrf'),
            };
            window.eventBus.$emit(this.$store.state.eventConstants.showStepLoading);
            window.eventBus.$emit(
                this.$store.state.eventConstants.submitStep,
                sendData,
                (data) => {
                    if (data.redirect) {
                        window.location = data.redirect;
                    }
                    window.eventBus.$emit(this.$store.state.eventConstants.hideStepLoading);
                    window.eventBus.$emit(this.$store.state.eventConstants.applyData, data);
                },
                (data) => {
                    window.eventBus.$emit(this.$store.state.eventConstants.applyData, data);
                    window.eventBus.$emit(this.$store.state.eventConstants.hideStepLoading);
                },
            );
        },
        crmChangeEvent(newValue, previousValue, crmData) {
            window.eventBus.$emit(this.$store.state.eventConstants.hidePageMessage);
            this.authorization = crmData.authorization;
            this.selectedCRM = newValue;
            this.hasPreview = crmData.hasPreview;
            this.previewCheckboxChecked = false;
            this.crmData = crmData;
            if (this.active === undefined
                || (
                    this.active !== undefined && !has(this.authorization, this.active)
                )
            ) {
                this.active = Object.keys(this.authorization)[0];
            }

            this.$emit('changeCrm');
        },
        isLocked() {
            if (this.locked === true) {
                return true;
            }

            return !this.getData(`${this.type}.canChangePlatform`, true);
        },
        onFieldFocus(credential) {
            this.$emit('onFieldFocus', credential);
        },
        changeTab() {
            this.$emit('changeTab');
        },
        async useConnection(connection) {
            this.loading = true;

            try {
                const data = await connectionGetConnectData(connection.id, this.type, this.product);

                this.authorization = data.platforms[connection.platform.type].authorization;

                await this.setTypeData(data[this.type]);
            } catch ({ message }) {
                this.$notify.error({
                    title:    this.$t('request error notification'),
                    message,
                    duration: 7500,
                });
            } finally {
                this.loading = false;
            }
        },
        async newConnection(platform) {
            this.authorization = this.getPlatform(this.type, platform).authorization;

            await this.setTypeData(undefined);
        },
        async setTypeData(data) {
            const path = [...this.dataPath.split('.'), this.type];

            reactive.set(this.$store.state, path, data);

            this.show = false;
            await this.$nextTick();
            this.show = true;
        },
    },
};
</script>
