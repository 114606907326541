var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"inline-flex items-center gap-x-2 font-medium leading-none transition-colors duration-100 font-avenir disabled:cursor-not-allowed disabled:opacity-60",class:[
        _setup.sizeClass,
        _setup.textClass,
        _setup.typeClass,
        _setup.borderClass,
        _setup.roundClass,
        _vm.stretch ? 'justify-start flex-1' : 'justify-center',
        {
            '[&:not(:first-child)]:-ml-px hover:z-10 active:z-10': _setup.isGroup && _setup.borderClass,
        },
    ],attrs:{"type":_vm.nativeType,"disabled":_vm.disabled || _vm.loading,"aria-disabled":_vm.disabled || _vm.loading},on:{"click":_setup.click}},[(_vm.loading)?[(_vm.$slots.loading)?_vm._t("loading"):_c('svg',{staticClass:"animate-spin",class:_setup.loaderClass,attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24"}},[_c('circle',{staticClass:"opacity-25",attrs:{"cx":"12","cy":"12","r":"10","stroke":"currentColor","stroke-width":"4"}}),_c('path',{staticClass:"opacity-75",attrs:{"fill":"currentColor","d":"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"}})])]:[_vm._t("prefix",function(){return [(_vm.icon)?_c('base-icon',_vm._b({class:_setup.iconClass},'base-icon',_vm.icon,false)):_vm._e()]},{"disabled":_vm.disabled})],(_vm.$slots.default)?_c('span',{class:_setup.labelClass},[_vm._t("default",null,{"disabled":_vm.disabled})],2):(_vm.label !== undefined)?_c('span',{class:_setup.labelClass,domProps:{"textContent":_vm._s(_vm.label)}}):_vm._e(),_vm._t("suffix",null,{"disabled":_vm.disabled})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }